.contact-form-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  text-align: center;
  font-family: "Poppins";
}

.contact-form-container h2 {
  color: #333333;
  font-size: 40px;
  font-weight: 700;
}

.form-group {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  font-family: "Poppins";
}

.form-group button {
  width: 100%;
  padding: 10px;
  border: 1px solid #222222;
  border-radius: 8px;
  background-color: #222222;
  box-shadow: 0px 4px 4px #8890917c;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
}

.form-group button:hover {
  transform: scale(1.03);
}

.form-group button:active {
  background-color: #FFFFFF;
  color: #222222;
  border: 1px solid #222222;
}

/* Estilos responsivos para pantallas con un ancho máximo de 1024px */
@media (max-width: 1024px) {
  .contact-form-container h2 {
    font-size: 32px;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
  }

  .form-group button {
    padding: 8px;
    font-size: 14px;
  }
}

/* Estilos responsivos para pantallas con un ancho máximo de 768px */
@media (max-width: 768px) {
  .contact-form-container h2 {
    font-size: 24px;
  }

  .form-group input,
  .form-group textarea {
    padding: 6px;
  }

  .form-group button {
    padding: 10px;
    font-size: 12px;
  }
}
