.blog{
    width: 65%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0px 140px;
    margin-bottom: 150px;
    background: linear-gradient(180deg, #01D6F6 0%, #009BFC 60%);
    gap: 30px;
}

.blog h1{
    color: #FFFFFF;
    font-size: 55px;
    font-weight: 600;
}