/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 50px;
    border-radius: 5px;
    max-width: 900px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .btn-cerrar {
    width: 100px;
    height: 35px;
    outline: none;
    border: 1px solid #222222;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    background: #222222;
    cursor: pointer;
    box-shadow: 0px 4px 4px #8890917c;
    margin: 20px 0; /* Ajusta el margen según sea necesario para tu modal */
}

.btn-cerrar:hover {
    transform: scale(1.07);
}

.btn-cerrar:active {
    background-color: #FFFFFF;
    color: #222222;
    border: 1px solid #222222;
}