.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  box-shadow: 0 1px 3px -2px black;
  background-color: #F2F2F2;
  z-index: 1000;
}

.nav-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-logo img {
  width: 60px;
  height: auto;
}

.nav-texto-logo img {
  width: 180px;
  height: auto;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 40px;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
}

.nav-menu a {
  text-decoration: none;
  color: #404040;
}

.nav-menu a.active {
  border-bottom: 2px solid #404040; /* Ejemplo de estilo para la línea debajo de la pestaña activa */
  color: #404040; /* Cambia el color del texto si es necesario */
}

.nav-menu a:hover {
  color: #707070;
}

.nav-menu li {
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Change alignment to left */
  justify-content: center;
  gap: 3px;
  cursor: pointer;
}

.nav-agendar-cita {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-agendar-cita button,
.nav-agendar-cita a,
.nav-agendar-cita-mobile button,
.nav-agendar-cita-mobile a {
  width: 150px;
  height: 28px;
  outline: none;
  border: 1px solid #333333;
  border-radius: 8px;
  color: #FFFFFF;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  background: #333333;
  cursor: pointer;
  box-shadow: 0px 4px 4px #8890917c;
  text-decoration: none;
  text-align: center;
}

.nav-agendar-cita button:hover,
.nav-agendar-cita a:hover,
.nav-agendar-cita-mobile button:hover,
.nav-agendar-cita-mobile a:hover {
  transform: scale(1.07);
  color: #FFFFFF;
}

.nav-agendar-cita button:active,
.nav-agendar-cita a:active,
.nav-agendar-cita-mobile button:active,
.nav-agendar-cita-mobile a:active {
  background-color: #FFFFFF;
  color: #70707070;
  border: 1px solid #70707070;
}

.nav-agendar-cita-mobile {
  margin-bottom: 20px; /* Añade margen inferior para separar del borde del contenedor */
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  top: 100%;
}

.nav-menu li:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  text-align: left; /* Change alignment to left */
  white-space: nowrap;
}

/* Ajustes para pantallas grandes */
@media (min-width: 1024px) {
  .nav-menu {
    gap: 20px; /* Reduce el gap entre los elementos del menú */
  }

  .nav-agendar-cita {
    margin-left: 20px; /* Añade margen para separar el botón del menú */
  }
}

/* Ajustes para pantallas medianas */
@media (max-width: 1024px) {
  .nav-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav-agendar-cita {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-agendar-cita button,
  .nav-agendar-cita a {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    overflow-x: hidden; /* Evita el desbordamiento lateral */
    max-width: 100%; /* Asegura que el menú no exceda el ancho del contenedor */
  }

  .nav-menu.active {
    display: flex;
    align-items: center; /* Cambiar a center para centrar los elementos */
  }

  .nav-menu li {
    width: 100%;
    text-align: left; /* Change alignment to left */
    padding-left: 20px; /* Add padding to align text to left */
    font-size: 18px; /* Larger font size */
    font-weight: 700;
    border-bottom: 1px solid #E0E0E0;
  }

  .nav-logo p {
    font-size: 16px;
  }

  .nav-hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #333333;
    border-radius: 8px;
  }

  .hamburger-icon {
    color: #FFFFFF;
  }

  .nav-hamburger:hover {
    transform: scale(1.07);
  }

  .nav-agendar-cita {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px; /* Añade un margen superior para separar el botón del menú */
  }

  .nav-agendar-cita a {
    display: inline-block;
  }

  .dropdown-menu {
    position: relative;
    box-shadow: none;
    background-color: transparent;
  }

  .dropdown-menu li {
    margin-left: 0;
  }
}
