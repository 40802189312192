.search-bar {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .search-bar input[type="text"],
  .search-bar select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
  }
  
  .search-bar input[type="text"] {
    flex-grow: 1;
  }
  
  .search-bar select {
    min-width: 150px;
  }
  
  /* Estilos para dispositivos más pequeños */
  @media (max-width: 768px) {
    .search-bar {
      flex-direction: column;
    }
    
    .search-bar input[type="text"],
    .search-bar select {
      width: 100%; /* Hacer que los inputs ocupen todo el ancho en dispositivos móviles */
    }
  }
  