.servicios-contenedor {
  text-align: center;
  margin: 0 auto;
  padding: 80px;
  font-family: 'Poppins';
  border-bottom: 0.05em solid #70707070;
}

.servicios-contenedor h2 {
  color: #333333;
  font-size: 40px;
  font-weight: 700px;
}

.servicios {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.servicio {
  width: 45%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 40vh;
  border-radius: 3%;
  display: flex;
  flex-direction: row; /* Cambio importante aquí */
  transition: transform 0.3s ease;
}

.servicio:hover {
  transform: scale(1.05);
}

.servicio-imagen {
  width: 50%;
  height: auto; /* Define una altura si es necesario para mantener un área específica */
  display: flex; /* Usa flexbox para centrar el contenido */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  gap: 20px; /* Espacio entre los elementos internos si hay más de uno */
  border-radius: 15px; /* Esto aplica redondeo al contenedor si es que quieres bordes redondeados */
}

.servicio-imagen img {
  width: 80%; /* Ajusta el ancho según sea necesario */
  height: auto; /* Mantén la proporción de aspecto */
  display: block; 
  border-radius: 15px;
}

.servicio-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  margin-right: 30px;
}

.servicio-info h3 {
  margin-top: 20px; /* Cambiado de 10% a un valor fijo */
  font-size: 20px;
}

.servicio-info p {
  font-size: 15px;
  color: #707070;
}

.servicio-info .leer-mas {
  width: 100px;
  height: 35px;
  outline: none;
  border: 1px solid #222222;
  border-radius: 8px;
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  background: #222222;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(136, 144, 145, 0.48);
  margin-bottom: 10%;
}

.leer-mas:hover {
  transform: scale(1.07);
}

.leer-mas:active {
  background-color: #FFFFFF;
  color: #222222;
  border: 1px solid #222222;
}

/* Estilos responsivos para pantallas con un ancho máximo de 1024px */
@media (max-width: 1024px) {
  .servicio {
    width: 70%;
  }

  .servicios-contenedor h2 {
    font-size: 32px;
  }

  .servicio-info h3 {
    font-size: 18px;
  }

  .servicio-info p {
    font-size: 14px;
  }

  .servicio-info .leer-mas {
    width: 120px;
    height: 40px;
    font-size: 14px;
  }
}

/* Estilos responsivos para pantallas con un ancho máximo de 768px */
@media (max-width: 768px) {
  .servicio {
    width: 90%;
    flex-direction: column;
    align-items: center; /* Center items on small screens */
  }

  .servicio-imagen {
    width: 100%;
  }

  .servicio-imagen img {
    width: 100%; /* Adjust the width on small screens */
  }

  .servicio-info {
    width: 100%;
    align-items: center; /* Center text on small screens */
    text-align: center;
    margin-right: 0;
  }

  .servicio-info h3 {
    font-size: 16px;
  }

  .servicio-info p {
    font-size: 14px;
  }

  .servicio-info .leer-mas {
    width: 150px;
    height: 40px;
    font-size: 16px;
  }
}
