.equipo-contenedor {
    text-align: center;
    max-width: auto;
    margin: 0 auto;
    font-family: 'Poppins';

    border-bottom: 0.05em solid #70707070;
  }
  
  .equipo-contenedor h2{
    color: #333333;
    font-size: 40px;
    font-weight: 700;
  }
  .integrantes {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 20px;
    margin-bottom: 60px;
  }
  
  .tarjeta-integrante {
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    transition: transform 0.3s ease;
  }
  
  .tarjeta-integrante:hover {
    transform: scale(1.05);
  }
  
  .imagen-integrante {
    width: 100%;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .tarjeta-integrante h3 {
    color: #707070;
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .tarjeta-integrante p {
    color: #C0C0C0;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  .btn-linkedin {
    background-color: #222222; /* Fondo oscuro por defecto */
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 20px;
  }
  

  .btn-linkedin:active {
    --color-btn: white; /* Esto no cambiará el ícono directamente porque el ícono utiliza color desde React state */
  }
  
  

  .btn-linkedin:hover {
    transform: scale(1.07);
  }
  
  .btn-linkedin:active {
    background-color: #FFFFFF;
    border: 1px solid #222222;
    --color-btn: #222222;
  }
  
  /* Ícono de LinkedIn */
  .btn-linkedin svg{
    vertical-align: middle;
    color: white;
  }

  .btn-linkedin svg:active{
    vertical-align: middle;
    color: #222222;
  }
  