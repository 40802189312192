/* Estilos generales */
.resumen {
    min-height: 40vh;
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se ajusten si no hay suficiente espacio */
    font-family: 'Poppins';
    border-bottom: 0.05em solid #70707070;
}

/* Estilos para el contenedor izquierdo */
.resumen-left {
    margin-top: 5%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Cambia 'left' por 'flex-start' */
    gap: 20px;
    padding-left: 100px;
    padding-right: 100px;
    line-height: 1.2;
    margin-bottom: 5%;
}

.resumen-left h2 {
    color: #333333;
    font-size: 40px;
    font-weight: 700;
}

.resumen-left p {
    color: #707070;
    font-size: 15px;
    font-weight: 400;
}

/* Estilos para el botón */
.conocenos-btn {
    display: flex;
    gap: 15px;
    width: 310px;
}

.conocenos-btn button {
    width: 100px;
    height: 35px;
    outline: none;
    border: 1px solid #222222;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    background: #222222;
    cursor: pointer;
    box-shadow: 0px 4px 4px #8890917c;
}

.conocenos-btn button:hover {
    transform: scale(1.07);
}

.conocenos-btn button:active {
    background-color: #FFFFFF;
    color: #222222;
    border: 1px solid #222222;
}

/* Estilos para el contenedor derecho */
.resumen-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
}

.resumen-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Estilos responsivos para pantallas con un ancho máximo de 1024px */
@media (max-width: 1024px) {
    .resumen-left {
        padding-left: 50px;
        padding-right: 50px;
    }

    .resumen-left h2 {
        font-size: 32px;
    }

    .resumen-left p {
        font-size: 14px;
    }

    .conocenos-btn {
        width: 260px;
    }

    .conocenos-btn button {
        width: 90px;
        height: 32px;
        font-size: 11px;
    }
}

/* Estilos responsivos para pantallas con un ancho máximo de 768px */
@media (max-width: 768px) {
    .resumen {
        flex-direction: column; /* Cambia la dirección del flex a columna */
        align-items: center;
        text-align: center; /* Centra el texto */
    }

    .resumen-left {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
        align-items: center; /* Centra el contenido */
        order: 1; /* Asegura que el texto aparezca primero */
    }

    .resumen-left h2 {
        font-size: 40px;
    }

    .resumen-left p {
        font-size: 18px; /* Agranda la letra en pantallas pequeñas */
    }

    .conocenos-btn {
        width: 200px;
        justify-content: center; /* Centra los botones */
    }

    .conocenos-btn button {
        width: 150px; /* Agranda el botón en pantallas pequeñas */
        height: 40px;
        font-size: 16px; /* Agranda la letra del botón en pantallas pequeñas */
    }

    .resumen-right {
        width: 100%; /* Asegura que la imagen ocupe todo el ancho */
        margin-bottom: 20px; /* Añade un margen inferior */
        order: 2; /* Coloca la imagen debajo del texto */
    }

    .resumen-right img {
        width: 100%; /* Asegura que la imagen ocupe todo el ancho */
        height: auto; /* Mantiene la proporción de la imagen */
    }
}
