.item {
    width: 350px;
    height: auto;
    padding: 20px; /* Ajustado para menos padding */
    box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Opcional: añade sombra para mejor visualización */
}

.item img {
    max-width: 75%;
    height: auto;
    display: block;
    margin: 0 auto 10px; /* 0 margen superior, auto para los márgenes laterales, 10px margen inferior */
}


.item-detail, .item-prices, .item-specifications, .item-units, .item-type {
    display: flex;
    align-items: center; /* Asegura que los elementos estén alineados verticalmente */
    gap: 20px;
    margin: 6px 0; /* Ajusta el margen para una mejor separación */
}

.item-detail span, .item-prices span, .item-specifications span, .item-units span, .item-type span {
    color: #C9C5C3;
    font-weight: 600;
    font-size: 15px; /* Corregido */
}

.item-detail p, .item-prices p, .item-specifications p, .item-units p, .item-type p {
    margin: 0; /* Elimina el margen por defecto de los párrafos */
    color: #707070; /* Ajusta el color si es necesario */
}

.item-specifications span, .item-specifications p {
    color: #C9C5C3; /* Especifica color para especificaciones si se desea */
    font-size: 15px; /* Corregido y ajustado */
}

.item:hover {
    transform: scale(1.05); /* Efecto de hover para escalar el ítem */
}   

.item button {
    margin-top: 10px;
    padding: 8px 16px;
    border: none;
    font-family: 'Poppins';
    background-color: #333333; /* Cambia al color que prefieras */
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.item button:hover {
    background-color: #707070; /* Oscurece el botón al pasar el mouse */
}