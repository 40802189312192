.historia-contenedor {
  min-height: 40vh;
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se ajusten si no hay suficiente espacio */
  font-family: 'Poppins';
  border-bottom: 0.05em solid #70707070;
  overflow: hidden; /* Evita desbordamiento */
  width: 100%; /* Asegura que el contenedor ocupe el 100% del ancho */
}

.historia-left {
  margin-top: 5%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1.2;
  margin-bottom: 5%;
  box-sizing: border-box; /* Incluye padding en el ancho total */
}

.historia-left h2 {
  color: #333333;
  font-size: 40px;
  font-weight: 700;
}

.historia-left p {
  color: #707070;
  font-size: 15px;
  font-weight: 400;
}

.historia-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; /* Centra la imagen en el contenedor */
  min-height: 40vh; /* Asegura una altura mínima para el contenedor */
  padding: 20px; /* Añade padding para evitar desbordamiento */
  box-sizing: border-box; /* Incluye padding en el ancho total */
}

.historia-right img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Estilos responsivos para pantallas con un ancho máximo de 1024px */
@media (max-width: 1024px) {
  .historia-left h2 {
    font-size: 32px;
  }

  .historia-left p {
    font-size: 14px;
  }
}

/* Estilos responsivos para pantallas con un ancho máximo de 768px */
@media (max-width: 768px) {
  .historia-contenedor {
    flex-direction: column;
    text-align: center;
    padding: 0; /* Eliminar padding extra */
  }

  .historia-left {
    align-items: center;
    padding: 0 20px; /* Ajustar padding para evitar desbordamiento */
  }

  .historia-left h2 {
    font-size: 24px;
  }

  .historia-left p {
    font-size: 14px;
  }

  .historia-right {
    width: 100%;
    padding: 0; /* Eliminar padding extra */
  }

  .historia-right img {
    width: 100%;
    height: auto;
  }
}
